// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'
// import Helmet from 'react-helmet'
// import Slick from 'react-slick'
import Iframe from 'react-iframe'
import Analytics from '../../utils/analytics'
// import RenderIf from '../components/RenderIf'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Nav from '../../components/Nav'
// import Icon from '../components/Icon'
import PaddedContent from '../../components/PaddedContent'
// import Button from '../components/Button'
// import Devices from '../components/Devices'
// import { type FluidWithWebp } from '../types'
import { Header, TabletFrame, OuterWrapper, InnerWrapper } from '../../styles/pages/demo'

type Props = {
  location: any,
}

type State = {}

type StaticQueryData = {
  contentfulDemoPassengerPortal: {|
    title: string,
    headingTitle: string,
    headingSubtitle: string,
    // tabletFrame: { // KEEP THIS FOR WHEN FIXED BELOW
    //   title: string,
    //   ...FluidWithWebp,
    //   file: {
    //     url: string,
    //     details: {
    //       image: {
    //         width: string,
    //       },
    //     },
    //   },
    // },
  |},
}

class DemoPassengerPortal extends React.Component<Props, State> {
  state = {}

  componentDidMount() {
    Analytics.pageview()
  }

  render() {
    const { location } = this.props

    return (
      <StaticQuery
        query={passengerPortalQuery}
        render={(data: StaticQueryData) => {
          const { title, headingTitle, headingSubtitle } = data.contentfulDemoPassengerPortal

          return (
            <Layout dependencies={['slick']}>
              <SEO title={title} location={location} />
              <Header>
                <Header.Background />
                <Nav location={location} />
                <PaddedContent>
                  <Header.Title>{headingTitle}</Header.Title>
                  <Header.Subtitle>{headingSubtitle}</Header.Subtitle>
                </PaddedContent>
              </Header>
              <PaddedContent>
                <div style={{ height: `800px`, marginTop: `50px` }}>
                  <OuterWrapper ratio={16 / 10}>
                    <InnerWrapper
                      style={{
                        width: `100%`,
                        height: `100%`,
                        margin: `auto`,
                        objectFit: `contain`,
                        display: `block`,
                      }}
                    >
                      <TabletFrame
                        // url={tabletFrame.file.url}
                        style={{
                          backgroundImage: `url(https://images.ctfassets.net/2685tse8om2z/6o0eNCOkYnZDhIB8L03UCS/4f43c7536e7c8beeae85ba77b226a650/tablet-frame_with_black_bg.png)`,
                        }}
                      >
                        <div
                          style={{
                            width: `98%`,
                            height: `100%`,
                            paddingLeft: `8%`,
                            paddingTop: `4%`,
                          }}
                        >
                          <Iframe
                            url="http://showdemo.goji.aero"
                            width="85%"
                            height="85%"
                            id="myId"
                            styles={{ margin: `125px`, height: `10%`, position: `absolute` }}
                            allowFullScreen
                          />
                        </div>
                      </TabletFrame>
                    </InnerWrapper>
                  </OuterWrapper>
                  {/* </div> */}
                </div>
              </PaddedContent>
            </Layout>
          )
        }}
      />
    )
  }
}

export default DemoPassengerPortal

const passengerPortalQuery = graphql`
  query passengerPortalQuery {
    contentfulDemoPassengerPortal {
      title
      headingTitle
      headingSubtitle
    }
  }
`

// tabletFrame {
//   title
//   xxxfluid(maxWidth: 760, quality: 97) {
//     xxx..GatsbyContentfulFluid_withWebp
//   }
//   file {
//     url
//     details {
//       image {
//         width
//         height
//       }
//     }
//   }
// }
